<script>
import axios from "axios";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import router from "../../../router/index";
import { authHeader } from "../../../helpers/auth-header";

import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";

import ListingTable from "@/components/listings/listing-table";
// import StatusGroup from "@/components/forms/status-group";
import InputGroup from "@/components/forms/input-group";

export default {
	components: {
		Layout,
		PageHeader,
		ConfirmationModal,
		MessageModal,
		ListingTable,
		InputGroup,
		// StatusGroup
	},
	data() {
		return {
			title: "Member",
			items: [
				{
					text: "Member",
				},
			],
			filter: {
				name: "",
				email: "",
				phone: "",
				status: 2,
			},
			fields: [
				// { key: 'checkbox', sortable: false, label: '' },
				{ key: "id", sortable: true, label: "ID" },
				{ key: "status", sortable: false, label: "Status" },
				{ key: "name", sortable: true, label: "Name" },
				{ key: "phone", sortable: true, label: "Phone" },
				{ key: "email", sortable: true, label: "Email" },
				{ key: "created_at", sortable: true, label: "Register Date" },
				{ key: "is_agent", label: "Is Agent?" },
				{ key: "action" },
				// { key: "status", sortable: true, label: "Status" },
			],
		};
	},
	computed: {},
	watch: {},
	async mounted() {
		// Set the initial number of items
		// await this.retrieveRecords();
	},
	methods: {
		goToEditPage(id) {
			router.push({ name: "Member Form", params: { id } });
		},

		deleteRecord(id) {
			const deleteCallback = async () => {
				await axios.delete(`${process.env.VUE_APP_APIURL}/member/${id}`, {
					headers: authHeader(),
				});
				this.filterTable();
				this.$refs.confirmationModal.closeModal();
				this.$refs.messageModal.showModal("The record has been deleted");
			};
			this.$refs.confirmationModal.showModal(
				`Confirm delete member (ID: ${id})?`,
				deleteCallback
			);
		},

		setMemberView(filter) {
			this.filter = Object.assign(this.filter, filter);
		},

		initialSetting() {
			// if (typeof(this.filter.selected_role)  === 'string') {
			//     this.filter.selected_role = this.filter.selected_role.split(',');
			// }
		},

		filterTable() {
			const filter = JSON.parse(JSON.stringify(this.filter));
			// if (filter.selected_role !== null) {
			//     const roles = [];
			//     for (const role of filter.selected_role) {
			//         roles.push(role);
			//     }
			//     filter.selected_role = roles.join(',');
			// }

			this.$refs.listingTable.filterTable(filter);
		},
		resetTable() {
			this.filter = {
				name: "",
				email: "",
				phone: "",
				status: 2,
			};
			const filter = this.filter;
			this.$refs.listingTable.filterTable(filter);
		},
	},
};
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<ConfirmationModal ref="confirmationModal" />
		<MessageModal ref="messageModal" />
		<div class="row">
			<div class="col-lg-12">
				<div class="card" style="border-radius: 15px;">
					<div class="card-body pt-0">
						<div class="row mt-2">
							<div class="col-sm-12 col-md-3 col-lg-3 mt-2 mb-0">
								<InputGroup type="text" id="name" displayName="Name"
									v-model="filter.name" :onEnter="filterTable" />
							</div>
							<div class="col-sm-12 col-md-3 col-lg-3 mt-2">
								<InputGroup type="email" id="email" displayName="Phone" v-model="filter.phone"
									:onEnter="filterTable" />
							</div>
							<div class="col-sm-12 col-md-3 col-lg-3 mt-2">
								<InputGroup type="email" id="email" displayName="Email" v-model="filter.email"
									:onEnter="filterTable" />
							</div>

							<!-- <div class="col-sm-12 col-md-3 col-lg-3">
                <StatusGroup v-model="filter.status" :statusVal="2"/>
              </div> -->
							<div class="col-sm-12 d-flex justify-content-end" style="margin-top: 12px;">
								<a href="javascript:void(0);" class="btn btn-orange" @click="filterTable">
									Filter
								</a>
								<a href="javascript:void(0);" class="btn btn-filter" style="margin-left: 11px;"
									@click="resetTable">
									Reset
								</a>
							</div>
						</div>
						<ListingTable ref="listingTable" :fields="fields" :setMemberView="setMemberView"
							:initialSetting="initialSetting" path="member" listName="members">
							<template v-slot:cell(status)="row">
								<div class="badge font-size-12" :class="{
									'badge-danger': parseInt(`${row.value}`) === 0,
									'badge-success': parseInt(`${row.value}`) === 1,
								}">
									{{ parseInt(`${row.value}`) === 1 ? "Active" : "Inactive" }}
								</div>
							</template>

							<template v-slot:cell(action)="{ item }">
								<router-link class="mr-2 grey-icon-color"
									:to="{ name: 'Member View', params: { id: item.id } }"><i
										class="ri-eye-line font-size-18" v-b-tooltip.hover title="View"></i>
								</router-link>

								<router-link class="mr-2 grey-icon-color"
									:to="{ name: 'Member Edit Form', params: { id: item.id } }"><i
										class="ri-pencil-line font-size-18" v-b-tooltip.hover
										title="Edit"></i></router-link>
								<a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete"
									@click="deleteRecord(item.id)">
									<i class="ri-delete-bin-line font-size-18"></i>
								</a>
							</template>
						</ListingTable>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>
